<template>
  <div class="capture-button">
    <v-card-actions class="justify-center">
      <v-layout row class="fab-container" align-baseline>
        <v-slide-x-reverse-transition hide-on-leave>
          <v-btn
            v-on:click="addPage()"
            :style="{
              visibility: capturedFirstPage && !capturing ? 'visible' : 'hidden'
            }"
            v-if="capturedFirstPage && !capturing ? 'visible' : 'hidden'"
            fab
            dark
            small
            class="space"
            color="green"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-slide-x-reverse-transition>
        <v-slide-x-transition>
          <v-btn
            v-on:click="capture()"
            fab
            dark
            color="primary"
            class="space"
            v-bind:loading="capturing"
          >
            <v-icon>mdi-camera-iris</v-icon>
          </v-btn>
        </v-slide-x-transition>
        <v-slide-x-transition>
          <v-btn
            v-on:click="faceMatch()"
            v-if="faceMatchReady && !capturing"
            fab
            dark
            small
            class="space"
            color="orange"
          >
            <v-icon>mdi-face-recognition</v-icon>
          </v-btn>
        </v-slide-x-transition>
        <v-slide-x-transition hide-on-leave>
          <v-btn
            v-on:click="cancelCapture()"
            v-show="capturing"
            fab
            dark
            x-small
            class="space"
            color="red"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-slide-x-transition>
        <v-slide-x-transition hide-on-leave>
          <v-btn
            v-on:click="clearCapture()"
            v-show="capturedFirstPage && !capturing"
            fab
            dark
            small
            class="space"
            color="red"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-slide-x-transition>
      </v-layout>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: "capture-button",
  data: () => ({
    capturing: false,
    registerlisteners: false,
    imageIdVis: 0,
    imageIdPhoto: 0,
    imageIdIr: 0,
    imageIdUv: 0,

    imageIdVis2: 0,
    imageIdPhoto2: 0,
    imageIdIr2: 0,
    imageIdUv2: 0,

    imageIdCoaxVis: 0,
    imageIdCoaxIr: 0,

    imageIdBarcode: 0,

    startNewCapture: true,
    pageNumber: 0,
    faceMatchReady: false,
    imageIdFaceMatchPhoto: 0,
    imageIdFaceMatchDG2: 0,
    capturedFirstPage: false,
    validationData: {}
  }),
  props: {
    webApiSession: Object,
    doFaceMatch: Boolean
  },
  methods: {
    clearCapture() {
      this.startNewCapture = true;
      this.capturedFirstPage = false;
      this.pageNumber = 0;
      this.validationData = new Object();
      this.$emit("newcapture");
      this.faceMatchReady = false;
    },
    addPage() {
      this.startNewCapture = false;
      this.pageNumber++;
      this.capture();
    },
    faceMatch() {
      if (this.doFaceMatch && this.faceMatchReady) {
        this.webApiSession.send_message(
          JSON.stringify({
            t: 67,
            i: this.webApiSession.getNextI(),
            d: {
              image_id: this.imageIdFaceMatchPhoto,
              sc_data: this.imageIdFaceMatchDG2
            }
          })
        );
      }
    },
    requestImage(image_id) {
      this.webApiSession.send_message(
        JSON.stringify({
          t: 16,
          i: this.webApiSession.getNextI(),
          d: { image_id }
        })
      );
    },
    validationDataComplete() {
      for (var key in this.validationData) {
        if (
          this.validationData[key] === null ||
          this.validationData[key] === ""
        ) {
          return false;
        }
      }
      return true;
    },
    cancelCapture() {
      if (!this.capturing) return;

      this.webApiSession.send_message(
        JSON.stringify({
          t: 38,
          i: this.webApiSession.getNextI()
        })
      );
    },
    capture() {
      if (this.capturing) return;

      if (this.startNewCapture) {
        this.$emit("newcapture");
        this.pageNumber = 0;
        this.faceMatchReady = false;
        this.imageIdFaceMatchPhoto = 0;
        this.imageIdFaceMatchDG2 = 0;
        this.capturedFirstPage = false;
      }
      this.startNewCapture = true;

      this.capturing = true;

      if (!this.registerlisteners) {
        this.webApiSession.send_message(
          JSON.stringify({
            t: 42,
            i: this.webApiSession.getNextI()
          })
        );

        this.registerlisteners = true;

        this.webApiSession.on("CAPTURED", () => {
          this.$emit("capturecomplete", true);
          // this.$emit("imagereceived", true);

          if (
            this.imageIdFaceMatchPhoto > 0 &&
            this.imageIdFaceMatchDG2 > 0 &&
            this.doFaceMatch
          ) {
            this.faceMatchReady = true;
          }
          this.capturedFirstPage = true;
          this.capturing = false;
        });

        this.webApiSession.on("FACEMATCH_RESPONSE", data => {
          this.$emit("facematchresult", "Face Match Result", data);
        });

        this.webApiSession.on("FACEMATCH_ERROR", data => {
          this.$emit("facematchresult", "Face Match Error", data);
        });

        this.webApiSession.on("EVENT", data => {
          this.$emit(
            "readerevent",
            this.webApiSession.getEventCode(data.eventId)
          );
        });

        this.webApiSession.on("CAPTURE_DOCFOUND", () => {
          this.$emit("capturedocfound");
        });

        this.webApiSession.on("CAPTURE_DATA_CAPTURED", data => {
          console.log(
            `CAPTURE_DATA_CAPTURED ${this.webApiSession.getReaderDataType(
              data.type
            )}[${data.type}]`
          );

          switch (this.webApiSession.getReaderDataType(data.type)) {
            case "IMAGEVIS":
              this.$emit("imagecaptured", "Visible", this.pageNumber);
              this.imageIdVis = data.id;
              this.requestImage(this.imageIdVis);
              break;
            case "IMAGEPHOTO":
              this.$emit("imagecaptured", "Photo", this.pageNumber);
              this.imageIdPhoto = data.id;
              if (this.doFaceMatch) {
                this.imageIdFaceMatchPhoto = data.id;
              }
              this.requestImage(this.imageIdPhoto);
              break;
            case "IMAGEIR":
              this.$emit("imagecaptured", "Infrared", this.pageNumber);
              this.imageIdIr = data.id;
              this.requestImage(this.imageIdIr);
              break;
            case "IMAGEUV":
              this.$emit("imagecaptured", "Ultraviolet", this.pageNumber);
              this.imageIdUv = data.id;
              this.requestImage(this.imageIdUv);
              break;
            case "IMAGECOAXVIS":
              this.$emit("imagecaptured", "Coax Visible", this.pageNumber);
              this.imageIdCoaxVis = data.id;
              this.requestImage(this.imageIdCoaxVis);
              break;
            case "IMAGECOAXIR":
              this.$emit("imagecaptured", "Coax Infrared", this.pageNumber);
              this.imageIdCoaxIr = data.id;
              this.requestImage(this.imageIdCoaxIr);
              break;
            case "IMAGEVISREAR":
              this.$emit("imagecaptured", "Visible 2", this.pageNumber);
              this.imageIdVis2 = data.id;
              this.requestImage(this.imageIdVis2);
              break;
            case "IMAGEIRREAR":
              this.$emit("imagecaptured", "Infrared 2", this.pageNumber);
              this.imageIdIr2 = data.id;
              this.requestImage(this.imageIdIr2);
              break;
            case "IMAGEUVREAR":
              this.$emit("imagecaptured", "Ultraviolet 2", this.pageNumber);
              this.imageIdUv2 = data.id;
              this.requestImage(this.imageIdUv2);
              break;
            case "SCDG2_PHOTO":
              this.$emit("edatacaptured", "DG2 Photo", this.pageNumber);
              this.imageIdFaceMatchDG2 = data.id;
              this.requestImage(this.imageIdFaceMatchDG2);
              break;
            case "IMAGEBARCODE":
              this.$emit("imagecaptured", "Barcode Image", this.pageNumber);
              this.imageIdBarcode = data.id;
              this.requestImage(this.imageIdBarcode);
              break;
            case "CODELINE_DATA":
              this.$emit("datacaptured", "Codeline", this.pageNumber);
              this.mrzId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;
            case "SCDG1_CODELINE_DATA":
              this.$emit("edatacaptured", "DG1 Codeline", this.pageNumber);
              this.dg1codelineId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;
            case "AAMVA_DATA":
              this.$emit("aamvadatacaptured", "AAMVA");
              this.aamvaId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;

            case "BARCODE_QRCODE":
              this.$emit("barcodedatacaptured", "QR Code");
              this.qrcodeId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;
            case "BARCODE_1D_UPC_EAN":
              this.$emit("barcodedatacaptured", "1D Barcode UPC EAN");
              this.barcode1dId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;

            case "BARCODE_1D_INDUSTRIAL_2_OF_5":
              this.$emit("barcodedatacaptured", "1D Barcode Industrial 2 of 5");
              this.barcode1dId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;

            case "BARCODE_1D_INTERLEAVED_2_OF_5":
              this.$emit(
                "barcodedatacaptured",
                "1D Barcode Interleaved 2 of 5"
              );
              this.barcode1dId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;

            case "BARCODE_1D_IATA_2_OF_5":
              this.$emit("barcodedatacaptured", "1D Barcode IATA 2 of 5");
              this.barcode1dId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;

            case "BARCODE_1D_3_OF_9":
              this.$emit("barcodedatacaptured", "1D Barcode 3 of 9");
              this.barcode1dId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;

            case "BARCODE_1D_128":
              this.$emit("barcodedatacaptured", "1D Barcode Code 128");
              this.barcode1dId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;

            case "BARCODE_1D_CODE_93":
              this.$emit("barcodedatacaptured", "1D Barcode Code 93");
              this.barcode1dId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;

            case "BARCODE_1D_CODABAR":
              this.$emit("barcodedatacaptured", "Barcode Codabar");
              this.barcode1dId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;

            case "BARCODE_PDF417":
              this.$emit("barcodedatacaptured", "Barcode PDF417");
              this.barcode1dId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;

            case "BARCODE_AZTECCODE":
              this.$emit("barcodedatacaptured", "Barcode Aztec");
              this.barcode1dId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;

            case "BARCODE_DATAMATRIX":
              this.$emit("barcodedatacaptured", "Barcode Data Matrix");
              this.barcode1dId = data.id;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;
            case "SCDG1_VALIDATE":
              this.$emit("edatacaptured", "Validation", this.pageNumber);
              this.validationData.SCDG1_VALIDATE = null;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;
            case "SCDG2_VALIDATE":
              this.$emit("edatacaptured", "Validation", this.pageNumber);
              this.validationData.SCDG2_VALIDATE = null;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;
            case "SCSIGNEDATTRS_VALIDATE":
              this.$emit("edatacaptured", "Validation", this.pageNumber);
              this.validationData.SCSIGNEDATTRS_VALIDATE = null;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;
            case "SCSIGNATURE_VALIDATE":
              this.$emit("edatacaptured", "Validation", this.pageNumber);
              this.validationData.SCSIGNATURE_VALIDATE = null;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;
            case "SCBAC_STATUS":
              this.$emit("edatacaptured", "Validation", this.pageNumber);
              this.validationData.SCBAC_STATUS = null;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;
            case "SAC_STATUS":
              this.$emit("edatacaptured", "Validation", this.pageNumber);
              this.validationData.SAC_STATUS = null;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;
            case "ACTIVE_AUTHENTICATION":
              this.$emit("edatacaptured", "Validation", this.pageNumber);
              this.validationData.ACTIVE_AUTHENTICATION = null;
              this.webApiSession.send_message(
                JSON.stringify({
                  t: 52,
                  i: this.webApiSession.getNextI(),
                  d: { id: data.id }
                })
              );
              break;

            default:
              if (data.type == 189) {
                this.$emit("idvresultcaptured", "IDV Results");
                this.webApiSession.send_message(
                  JSON.stringify({
                    t: 52,
                    i: this.webApiSession.getNextI(),
                    d: { id: data.id }
                  })
                );
              } else if (data.type == 190) {
                this.$emit("idvresultcaptured", "IDV Results");
                this.webApiSession.send_message(
                  JSON.stringify({
                    t: 52,
                    i: this.webApiSession.getNextI(),
                    d: { id: data.id }
                  })
                );
              } else if (data.type == 191) {
                this.$emit("idvresultcaptured", "IDV Results");
                this.webApiSession.send_message(
                  JSON.stringify({
                    t: 52,
                    i: this.webApiSession.getNextI(),
                    d: { id: data.id }
                  })
                );
              } else {
                console.log(
                  `Ignored Data Type ----- ${this.webApiSession.getReaderDataType(
                    data.type
                  )}[${data.type}]`
                );
              }
          }
        });
        this.webApiSession.on("RETURNED_DATA", data => {
          switch (this.webApiSession.getReaderDataType(data.type)) {
            case "CODELINE_DATA":
              this.$emit("datareceived", "Codeline", this.pageNumber, data);
              this.mrzId = null;
              break;
            case "SCDG1_CODELINE_DATA":
              this.$emit(
                "edatareceived",
                "DG1 Codeline",
                this.pageNumber,
                data.codeline_data
              );
              //this.$emit("datareceived", "Codeline", data);
              this.dg1codelineId = null;
              break;
            case "AAMVA_DATA":
              this.$emit("aamvadatareceived", "AAMVA", data.aamva_data);
              //this.$emit("datareceived", "Codeline", data);
              this.aamvaId = null;
              break;
            case "BARCODE_QRCODE":
              this.$emit("barcodedatareceived", "QR Code", data.data);
              //this.$emit("datareceived", "Codeline", data);
              this.qrcodeId = null;
              break;
            case "BARCODE_1D_UPC_EAN":
              this.$emit(
                "barcodedatareceived",
                "1D Barcode UPC EAN",
                data.data
              );
              //this.$emit("datareceived", "Codeline", data);
              this.barcode1dId = null;
              break;

            case "BARCODE_1D_INDUSTRIAL_2_OF_5":
              this.$emit(
                "barcodedatareceived",
                "1D Barcode Industrial 2 of 5",
                data.data
              );
              //this.$emit("datareceived", "Codeline", data);
              this.barcode1dId = null;
              break;
            case "BARCODE_1D_INTERLEAVED_2_OF_5":
              this.$emit(
                "barcodedatareceived",
                "1D Barcode Interleaved 2 of 5",
                data.data
              );
              //this.$emit("datareceived", "Codeline", data);
              this.barcode1dId = null;
              break;
            case "BARCODE_1D_IATA_2_OF_5":
              this.$emit(
                "barcodedatareceived",
                "1D Barcode IATA 2 of 5",
                data.data
              );
              //this.$emit("datareceived", "Codeline", data);
              this.barcode1dId = null;
              break;
            case "BARCODE_1D_3_OF_9":
              this.$emit("barcodedatareceived", "1D Barcode 3 of 9", data.data);
              //this.$emit("datareceived", "Codeline", data);
              this.barcode1dId = null;
              break;
            case "BARCODE_1D_128":
              this.$emit(
                "barcodedatareceived",
                "1D Barcode Code 128",
                data.data
              );
              //this.$emit("datareceived", "Codeline", data);
              this.barcode1dId = null;
              break;
            case "BARCODE_1D_CODE_93":
              this.$emit(
                "barcodedatareceived",
                "1D Barcode Code 93",
                data.data
              );
              //this.$emit("datareceived", "Codeline", data);
              this.barcode1dId = null;
              break;
            case "BARCODE_1D_CODABAR":
              this.$emit("barcodedatareceived", "Barcode Codabar", data.data);
              //this.$emit("datareceived", "Codeline", data);
              this.barcode1dId = null;
              break;
            case "BARCODE_PDF417":
              this.$emit("barcodedatareceived", "Barcode PDF417", data.data);
              //this.$emit("datareceived", "Codeline", data);
              this.barcode1dId = null;
              break;
            case "BARCODE_AZTECCODE":
              this.$emit("barcodedatareceived", "Barcode Aztec", data.data);
              //this.$emit("datareceived", "Codeline", data);
              this.barcode1dId = null;
              break;
            case "BARCODE_DATAMATRIX":
              this.$emit(
                "barcodedatareceived",
                "Barcode Data Matrix",
                data.data
              );
              //this.$emit("datareceived", "Codeline", data);
              this.barcode1dId = null;
              break;

            case "SCDG1_VALIDATE":
              this.validationData.SCDG1_VALIDATE = data.validation_code;
              if (this.validationDataComplete()) {
                this.$emit(
                  "edatareceived",
                  "Validation",
                  this.pageNumber,
                  this.validationData
                );
              }
              break;
            case "SCDG2_VALIDATE":
              this.validationData.SCDG2_VALIDATE = data.validation_code;
              if (this.validationDataComplete()) {
                this.$emit(
                  "edatareceived",
                  "Validation",
                  this.pageNumber,
                  this.validationData
                );
              }
              break;
            case "SCSIGNEDATTRS_VALIDATE":
              this.validationData.SCSIGNEDATTRS_VALIDATE = data.validation_code;
              if (this.validationDataComplete()) {
                this.$emit(
                  "edatareceived",
                  "Validation",
                  this.pageNumber,
                  this.validationData
                );
              }
              break;
            case "SCSIGNATURE_VALIDATE":
              this.validationData.SCSIGNATURE_VALIDATE = data.validation_code;
              if (this.validationDataComplete()) {
                this.$emit(
                  "edatareceived",
                  "Validation",
                  this.pageNumber,
                  this.validationData
                );
              }
              break;
            case "SCBAC_STATUS":
              this.validationData.SCBAC_STATUS = data.operation_status;
              if (this.validationDataComplete()) {
                this.$emit(
                  "edatareceived",
                  "Validation",
                  this.pageNumber,
                  this.validationData
                );
              }
              break;
            case "SAC_STATUS":
              this.validationData.SAC_STATUS = data.operation_status;
              if (this.validationDataComplete()) {
                this.$emit(
                  "edatareceived",
                  "Validation",
                  this.pageNumber,
                  this.validationData
                );
              }
              break;
            case "ACTIVE_AUTHENTICATION":
              this.validationData.ACTIVE_AUTHENTICATION = data.operation_status;
              if (this.validationDataComplete()) {
                this.$emit(
                  "edatareceived",
                  "Validation",
                  this.pageNumber,
                  this.validationData
                );
              }
              break;
            case 189:
              this.validationData.ACTIVE_AUTHENTICATION = data.operation_status;
              if (this.validationDataComplete()) {
                this.$emit(
                  "edatareceived",
                  "Validation",
                  this.pageNumber,
                  this.validationData
                );
              }
              break;
            default:
              if (data.type == 189) {
                this.$emit("idvresultreceived", "IDV Results", data.text);
                //this.$emit("datareceived", "Codeline", data);
              } else if (data.type == 190) {
                console.log("received 190");
                console.log(data);
                this.$emit("idvrfresultreceived", "IDV Results", data.text);
                //this.$emit("datareceived", "Codeline", data);
              } else if (data.type == 191) {
                this.$emit(
                  "idvfacematchresultreceived",
                  "IDV Results",
                  data.text
                );
                //this.$emit("datareceived", "Codeline", data);
              } else {
                // var data_buffer = Buffer.from(data.data, 'base64');

                // console.log(data_buffer.toString('hex'));
                // console.log(data_buffer.toString('utf8'))
                console.log("Ignored Data Received ---" + JSON.stringify(data));
              }
          }

          /** 
          if ("CODELINE_DATA" === this.webApiSession.getReaderDataType(data.type)) {
            this.$emit("datareceived", "Codeline", this.pageNumber,data);
            this.mrzId = null;
          } else if (this.dg1codelineId === data.id) {
            this.$emit("edatareceived", "DG1 Codeline",this.pageNumber, data.codeline_data);
            //this.$emit("datareceived", "Codeline", data);
            this.dg1codelineId = null;
          } else if (this.aamvaId === data.id) {
            this.$emit("aamvadatareceived", "AAMVA", data.aamva_data);
            //this.$emit("datareceived", "Codeline", data);
            this.aamvaId = null;
          } else{
            console.log("Ignored Data Received ---" + JSON.stringify(data))
          }

          */
        });
        this.webApiSession.on("RETURNED_IMAGE", data => {
          let type = "data:image/jpeg;base64,";

          var imageId = data.image_id;

          switch (imageId) {
            case this.imageIdVis:
              if (this.imageIdVis > 0) {
                this.$emit(
                  "imagereceived",
                  "Visible",
                  this.pageNumber,
                  type + data.img
                );
              }
              break;
            case this.imageIdPhoto:
              if (this.imageIdPhoto > 0) {
                this.$emit(
                  "imagereceived",
                  "Photo",
                  this.pageNumber,
                  type + data.img
                );
              }
              break;
            case this.imageIdIr:
              if (this.imageIdIr > 0) {
                this.$emit(
                  "imagereceived",
                  "Infrared",
                  this.pageNumber,
                  type + data.img
                );
              }
              break;
            case this.imageIdUv:
              if (this.imageIdUv > 0) {
                this.$emit(
                  "imagereceived",
                  "Ultraviolet",
                  this.pageNumber,
                  type + data.img
                );
              }
              break;
            case this.imageIdCoaxVis:
              if (this.imageIdCoaxVis > 0) {
                this.$emit(
                  "imagereceived",
                  "Coax Visible",
                  this.pageNumber,
                  type + data.img
                );
              }
              break;
            case this.imageIdCoaxIr:
              if (this.imageIdCoaxIr > 0) {
                this.$emit(
                  "imagereceived",
                  "Coax Visible",
                  this.pageNumber,
                  type + data.img
                );
              }
              break;
            case this.imageIdVis2:
              if (this.imageIdVis2 > 0) {
                this.$emit(
                  "imagereceived",
                  "Visible 2",
                  this.pageNumber,
                  type + data.img
                );
              }
              break;
            case this.imageIdPhoto2:
              if (this.imageIdPhoto2 > 0) {
                this.$emit(
                  "imagereceived",
                  "Photo 2",
                  this.pageNumber,
                  type + data.img
                );
              }
              break;
            case this.imageIdIr2:
              if (this.imageIdIr2 > 0) {
                this.$emit(
                  "imagereceived",
                  "Infrared 2",
                  this.pageNumber,
                  type + data.img
                );
              }
              break;
            case this.imageIdUv2:
              if (this.imageIdUv2 > 0) {
                this.$emit(
                  "imagereceived",
                  "Ultraviolet 2",
                  this.pageNumber,
                  type + data.img
                );
              }
              break;
            case this.imageIdFaceMatchDG2:
              if (this.imageIdFaceMatchDG2 > 0) {
                this.$emit(
                  "edatareceived",
                  "DG2 Photo",
                  this.pageNumber,
                  type + data.img
                );
              }
              break;
            case this.imageIdBarcode:
              if (this.imageIdBarcode > 0) {
                this.$emit(
                  "imagereceived",
                  "Barcode Image",
                  this.pageNumber,
                  type + data.img
                );
              }
              break;
          }
        });
      }

      this.webApiSession.capture();
    }
  }
};
</script>

<style scoped>
v-speed-dial {
}
.fab-container {
  position: fixed;
  bottom: 16px;
  z-index: 4;
  margin-left: 0px;
  margin-right: 0px;
}
.space {
  margin: 8px;
}
</style>
